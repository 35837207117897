<template>
    <div class="myeqm">
        <el-table
            :data="tableData"
            border
            align="center"
            stripe
            style="color: #161718"
            @row-dblclick="openDetails"
        >
            <el-table-column prop="code" label="设备编号" align="center" width="200"></el-table-column>
            <el-table-column prop="version" label="版本" align="center" width="120"></el-table-column>
            <el-table-column prop="palce" label="位置" align="center" width="300"></el-table-column>
            <el-table-column prop="road_name" label="道路编号及桩号" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.version_id == 1">--</span>
                    <span v-else>{{ scope.row.road_name }} -- {{ scope.row.mileage }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="alarm_count" label="总报警次数" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.version_id == 1">--</span>
                    <span v-else>{{ scope.row.alarm_count }}次</span>
                </template>
            </el-table-column>
            <el-table-column prop="total_time" label="总在线时长" align="center">
                <template slot-scope="scope">
                    <span>{{ changetime(scope.row.total_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="设备工作状态" align="center">
                <template slot-scope="scope">
                    <span style="color: #33cc66" v-if="scope.row.status == 1">在线</span>
                    <span style="color: red" v-else-if="scope.row.status == 0">离线</span>
                </template>
            </el-table-column>
            <el-table-column prop="save_video" label="是否保存报警视频" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.version_id == 1">--</span>
                    <el-switch
                        v-else-if="scope.row.status == 1"
                        v-model="scope.row.save_video"
                        @change="is_save(scope.row)"
                        active-color="#13ce66"
                        inactive-color="#999"
                        :inactive-value="'0'"
                        :active-value="'1'"
                    />
                    <el-switch
                        v-else-if="scope.row.status == 0"
                        disabled
                        v-model="scope.row.save_video"
                        @change="is_save(scope.row)"
                        active-color="#13ce66"
                        inactive-color="#999"
                        :inactive-value="'0'"
                        :active-value="'1'"
                    />
                    <!-- <el-switch
           
            disabled
            v-model="scope.row.save_video"
            @change="is_save(scope.row)"
            active-color="#13ce66"
            inactive-color="#999"
            :inactive-value="'0'"
            :active-value="'1'"
                    />-->
                </template>
                <!-- <el-switch
          v-model="value"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
                </el-switch>-->
            </el-table-column>
        </el-table>
        <el-row class="paging" align="middle" type="flex" justify="end">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage3"
                :page-size="pagesize"
                layout="prev,total, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </el-row>
        <el-dialog title="设备信息" :visible.sync="eqminfo" center width="35%">
            <table class="info_mess" border="1">
                <colgroup>
                    <col width="70" text-align="end" />
                    <col width="auto" text-align="start" />
                </colgroup>
                <tr>
                    <td class="mess_left" text-align="end">安放位置:</td>
                    <td class="mess_right" colspan="3">{{ infoplace }}</td>
                </tr>
                <tr>
                    <td class="mess_left">设备编号:</td>
                    <td class="mess_right" colspan="3">{{ infocode }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">工作时长:</td>
                    <td class="mess_right" colspan="3">{{ infotime_length }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">报警次数:</td>
                    <td class="mess_right" colspan="3">{{ infoamount }}</td>
                </tr>
                <tr>
                    <td class="mess_left">设备型号:</td>
                    <td class="mess_right" colspan="3">{{ infoversion }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">路面宽度:</td>
                    <td class="mess_right">{{ infosupervisewidth }}m</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">剩余电量:</td>
                    <td class="mess_right right_bottom">{{ infopower }}%</td>

                    <td class="mess_left left_bottom">道路编号:</td>
                    <td class="mess_right right_bottom">{{ roadnum }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">近报警速度:</td>
                    <td class="mess_right right_bottom">{{ infocloseapeed }}km/h</td>

                    <td class="mess_left left_bottom">里程桩号:</td>
                    <td class="mess_right right_bottom">{{ mileagenum }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">近报警距离:</td>
                    <td class="mess_right right_bottom">{{ infocloserange }}m</td>
                    <td class="mess_left left_bottom">道路去向:</td>
                    <td class="mess_right right_bottom">{{ roadtogo }}</td>
                </tr>
                <tr v-if="v_id !== 1">
                    <td class="mess_left">远报警速度:</td>
                    <td class="mess_right right_bottom">{{ inforemotespeed }}km/h</td>
                    <td class="mess_left left_bottom">行驶方向:</td>
                    <td class="mess_right right_bottom" v-if="drivingto == '0'">上行</td>
                    <td class="mess_right right_bottom" v-else-if="drivingto == '1'">下行</td>
                    <td class="mess_right right_bottom" v-else-if="drivingto == ''">无</td>
                </tr>
            </table>
            <div
                slot="footer"
                class="dialog-footer"
                style="
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
            >
                <el-button
                    class="footerbutton"
                    type="primary"
                    style="backgroundcolor: #0076f6"
                    @click="playVideo()"
                >前置监控</el-button>
                <el-button type="primary" class="footerbutton" style="backgroundcolor: #0076f6">后置监控</el-button>
                <el-button
                    class="footerbutton"
                    type="primary"
                    style="backgroundcolor: #0076f6"
                    @click="tohistory()"
                >过往报警</el-button>
                <el-button
                    class="footerbutton"
                    type="primary"
                    style="backgroundcolor: #0076f6"
                    @click="toeqmlog()"
                >设备日志</el-button>
            </div>
        </el-dialog>
        <div v-draw v-if="isplay" class="playVideo drag">
            <div>
                <Play
                    class="play"
                    style="position: relative; margin: 50px 0 0 0"
                    ref="playvideo"
                    :src="src"
                ></Play>
                <div class="massege" style="position: absolute; top: 0">
                    <p>设备编号：{{ infocode }}</p>
                    <p>当前位置：{{ infoplace }}</p>
                    <el-link type="primary" icon="el-icon-close" @click="playClose()"></el-link>
                </div>
                <div
                    v-if="loading == true"
                    class="loading"
                    v-loading="loading"
                    element-loading-text="正在开启远程摄像头拉取监控视频，请稍等...."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                ></div>
                <el-dropdown placement="top-end" @command="handleCommand" class="playmenu">
                    <span class="el-dropdown-link">
                        清晰度
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="a">高清</el-dropdown-item>
                        <el-dropdown-item command="b">流畅</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import {
    startplay,
    closeplay,
    looprequest,
    definition,
} from "../../api/indexMapApi";
import { getmyeqm, myeqmpage, savevideo } from "../../api/myeqmapi";
import { user, info, companymarker } from "../../api/indexMapApi";
import { Notification } from "element-ui";
import Play from "../../components/Play";
export default {
    components: {
        Play,
    },
    data() {
        return {
            isplay: false /**显示视频 */,
            eqminfo: false,
            value: true,
            tableData: [],
            currentPage3: 1,
            pagesize: 12,
            total: 32,
            eqmform: {},
            v_id: null,
            loading: false,
            companyid: null,
            src: null,
            idcode: null,
            url: "",
            isplay: false /**显示视频 */,
            overlay: null,
            infoWindow: null,
            isInfo: true,
            infocode: null,
            infocompany: null,
            infoversion: null,
            infocloserange: null,
            infocloseapeed: null,
            infosupervisewidth: null,
            inforemotespeed: null,
            infopower: null,
            infoamount: null,
            infoplace: null,
            have_video: null,
            infotime_length: "",
            timer: null,
            roadnum: null,
            mileagenum: null,
            roadtogo: null,
            drivingto: null,
        };
    },
    mounted() {
        this.getmyeqminfo();
    },
    methods: {
        openDetails(row) {
            if (row.status == "1") {
                console.log(row, "4444");
                console.log(row.status, "状态");
                console.log(row.code_id, "code_id");
                this.eqminfo = true;
                info(row.code_id)
                    .then((res) => {
                        console.log(res, "设备1111");
                        var infomessage = res.data.station_info[0];
                        console.log(infomessage);
                        console.log("这是信息框");
                        (this.companyid = infomessage.companyid),
                            (this.infocode = infomessage.code);
                        /**设备编号 */
                        this.infoplace = infomessage.place; /**位置 */
                        this.infocompany = infomessage.company;
                        /**公司 */
                        this.infoversion = infomessage.version; /**型号 */
                        (this.infocloserange =
                            infomessage.close_range) /**近报警距离 */,
                            (this.infocloseapeed =
                                infomessage.close_speed) /**近报警速度 */,
                            (this.infosupervisewidth =
                                infomessage.supervise_width) /**路面宽度 */,
                            (this.inforemotespeed =
                                infomessage.remote_speed) /**远距报警速度 */,
                            (this.infopower = infomessage.power) /**剩余电量 */,
                            (this.infoamount = infomessage.amount),
                            /**闯入计数 */ (this.info_v_id =
                                infomessage.version_id),
                            (this.have_video =
                                infomessage.have_video) /**是否有直播 */,
                            (this.hls_url = infomessage.hls) /**hls拉流地址 */,
                            (this.infotime_length = this.changetime(
                                infomessage.time_length
                            )),
                            (this.roadnum = infomessage.road_name),
                            (this.mileagenum = infomessage.mileage);
                        this.roadtogo = infomessage.toward;
                        this.drivingto = infomessage.upordown;
                    })
                    .catch((e) => {
                        this.$message.error(e);
                    });
            }

            // infoWindow.setAnchor("bottom-left");
        },
        playVideo() {
            this.isplay = !this.isplay;
            this.loading = true;
            var param = new URLSearchParams();
            console.log(this.infocode, "视频code");
            param.append("code", this.infocode);
            param.append("resolution", "");
            startplay(param).then((res) => {
                // console.log(res);
                // console.log(res.data.pcurl, "这是返回的url");

                if (res.data.code == 200) {
                    console.log(res);
                    this.src = res.data.pcurl;
                    // this.$refs.playvideo.videoSrc = res.data.pcurl;
                    this.$refs.playvideo.play(res.data.pcurl);
                    if (this.timer !== null) {
                        debugger;
                        clearInterval(this.timer);
                        console.log("测试直播心跳包");
                    } else {
                        this.timer = setInterval(() => {
                            if (document.getElementById("div") === null) {
                                clearInterval(this.timer);
                                this.isplay = false;
                            }
                            this.loopvideo();
                        }, 20000);
                    }
                    this.$nextTick(function () {
                        setTimeout(() => {
                            this.$refs.playvideo.init();
                            this.loading = false;
                        }, 40000);
                    });
                } else if (res.data.code == 5027) {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: "设备通信断开，请及时查看",
                        type: "warning",
                    });
                }
            });
        },
        loopvideo() {
            console.log("循环请求");
            var param = new URLSearchParams();
            param.append("code", this.infocode);
            looprequest(param)
                .then((res) => {
                    // console.log(res);
                    // console.log(res.data.pcurl, "这是返回的url");

                    if (res.status == 200) {
                        console.log(res);
                    } else if (res.data.code == 5027) {
                    }
                })
                .catch((e) => {});
        },
        getmyeqminfo() {
            getmyeqm()
                .then((res) => {
                    console.log(res);
                    console.log("这是我的设备");
                    //  for (var i = 0; i < res.data.devices.length; i++) {
                    //       this.changetime(res.data.devices[i].total_time)
                    //   }
                    this.tableData = res.data.devices;
                    this.pagesize = res.data.page_size;
                    this.total = res.data.total_pages;
                })
                .catch((err) => {});
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            myeqmpage(val)
                .then((res) => {
                    if (res.status == 200) {
                        console.log(res);
                        this.$forceUpdate();
                        this.tableData = res.data.devices;
                    }
                })
                .catch((e) => {
                    this.$message.error(e);
                });
            console.log(`当前页: ${val}`);
        },
        is_save(e) {
            console.log("这是保存视频按钮");
            console.log(e);
            if (e.save_video == "0") {
                // e.code要保存视频的设备id
                var param = new URLSearchParams();
                param.append("code", e.code);
                param.append("save_video", "notsavevideo");
                savevideo(param)
                    .then((res) => {
                        if (res.status == 200) {
                            console.log(res);
                        }
                    })
                    .catch((e) => {
                        this.$message.error(e);
                    });
                console.log("不保存");
            }
            if (e.save_video == "1") {
                var param = new URLSearchParams();
                param.append("code", e.code);
                param.append("save_video", "savevideo");
                savevideo(param)
                    .then((res) => {
                        if (res.status == 200) {
                            console.log(res);
                        }
                        if (res.data.code == 5027) {
                            console.log("返回关");
                            e.save_video = 0;
                            // this.$alert(` ${res.data.message}`, "提示", {
                            //   confirmButtonText: "确定",
                            //   callback: (action) => {
                            //     // this.$message({
                            //     //   type: "info",
                            //     //   message: ` ${res.data.message}`,
                            //     // });
                            //   },
                            // });
                        }
                    })
                    .catch((e) => {
                        this.$message.error(e);
                    });
                console.log("保存");
            }
        },
        changetime(str) {
            if (str) {
                return str < 60
                    ? str.toString() + "分钟"
                    : str % 60 === 0
                    ? (str / 60).toString() + "小时"
                    : Math.floor(str / 60).toString() +
                      "小时" +
                      (str % 60).toString() +
                      "分钟";
            } else {
                return "暂无数据";
            }
        },
        tohistory() {
            if (this.infocode != "") {
                this.eqminfo = false;
                this.$router.push({
                    name: "adalarmHistory",
                    params: {
                        code: this.idcode,
                        companyid: this.companyid,
                    },
                });
            }
        },
        /**关闭视频 */
        playClose() {
            clearInterval(this.timer);
            var param = new URLSearchParams();
            param.append("code", this.infocode);
            closeplay(param).then((res) => {
                console.log(res);
                console.log(res.data.pcurl);
                if (res.status === 200) {
                    console.log("关闭");
                } else if (res.code == 5027) {
                    this.$message({
                        showClose: true,
                        message: "设备通信断开，请及时查看",
                        type: "warning",
                    });
                }
            });
            if ((this.isplay = true)) {
                this.isplay = !this.isplay;
            }
        },
        /**设备日志 */
        toeqmlog() {
            if (this.infocode != "") {
                this.eqminfo = false;
                this.$router.push({
                    name: "adequipmentLog",
                    params: {
                        code: this.idcode,
                        companyid: this.companyid,
                    },
                });
            }
        },
        /**切换清晰度 */
        handleCommand(command) {
            console.log(command);
            if (command == "a") {
                var param = new URLSearchParams();
                param.append("code", this.infocode);
                param.append("resolution", "high");
                looprequest(param)
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.$refs.playvideo.play(res.data.pcurl);
                            this.$message({
                                showClose: true,
                                message: "正在切换至高清·",
                                type: "success",
                            });
                        } else if (res.data.code == 5027) {
                            this.$message({
                                showClose: true,
                                message: "切换失败",
                                type: "success",
                            });
                        }
                    })
                    .catch((e) => {});
            }
            if (command == "b") {
                var param = new URLSearchParams();
                param.append("code", this.infocode);
                param.append("resolution", "");
                looprequest(param)
                    .then((res) => {
                        if (res.data.code == 200) {
                            console.log(res);
                            this.$refs.playvideo.play(res.data.pcurl);
                            this.$message({
                                showClose: true,
                                message: "正在切换至流畅",
                                type: "success",
                            });
                        } else if (res.data.code == 5027) {
                            this.$message({
                                showClose: true,
                                message: "切换失败",
                                type: "success",
                            });
                        }
                    })
                    .catch((e) => {});
            }
        },
    },
    destroyed() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped>
@media only screen and (max-width: 1920px) {
    .myeqm {
        padding: 1.25rem 1.875rem 0 1.875rem;
    }
    .paging {
        width: 70%;
        float: right;
        /* background-color: chocolate; */
        text-align: end;
        margin-top: 1.25rem;
    }
    .video-js .vjs-big-play-button {
        font-size: 3em;
        line-height: 1.5em;
        height: 1.5em;
        width: 3em;
        display: block;
        position: absolute;
        top: 10.625rem;
        left: 22.5rem;
        padding: 0;
        cursor: pointer;
        opacity: 1;
        border: 0.06666em solid #fff;
        background-color: #2b333f;
        background-color: rgba(43, 51, 63, 0.7);
        border-radius: 0.3em;
        transition: all 0.4s;
    }
    .amap-info-sharp {
        bottom: -0.0625rem;
        left: 48.5%;
        position: absolute;
        color: #fff;
        z-index: -1;
        background-color: coral;
    }
    .info_mess {
        width: 100%;
        /* background-color: burlywood; */
        border-spacing: 0.25rem;
        border: none;
        margin-bottom: 0.625rem;
        font-family: "SourceHanSansSC-";
        margin-left: -0.625rem;
        font-size: 1.125rem;
    }
    .info_mess tr td {
        word-wrap: break-word;
        word-break: break-all;
        /* text-align: start; */
        border: none;
        font-family: "SourceHanSansSC-" !important;
    }
    .mess_left {
        text-align: end;
        font-weight: bold;
        margin-top: 0.3125rem;
        /* background-color: aquamarine; */
    }
    .left_bottom {
        width: 2.6875rem;
    }
    .right_bottom {
        width: 3.125rem;
    }
    .mess_right {
        margin-top: 0.3125rem;
        padding: 0 0 0 0.625rem;
        text-align: start;
        /* background-color: chartreuse; */
    }
    #del-div {
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        transform: scale(1.2);
        color: #000000;
        /* background-color: cyan; */
    }
    .el-link {
        color: #ffff !important;
    }
    .el-card {
        width: 26.5625rem !important;
    }
    #infoWindowTool {
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* background-color: darkgreen;
  width: 100%; */
    }
    .el-button {
    }
    .play {
        width: 50rem;
        height: 28.125rem;
        border: 0.0625rem solid black;
        background-color: cadetblue;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .loading {
        width: 50rem;
        height: 28.125rem;
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
    }
    .playmenu {
        position: absolute;
        z-index: 15;
        right: 0.9375rem;
        bottom: 0.3125rem;
    }
    element.style {
        top: 12.5rem;
        left: 12.5rem;

        z-index: 10;
    }
    .close {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        line-height: 2.5rem;
        z-index: 20;
        background-color: crimson;
    }
    .drag {
        top: 10.4375rem;
        left: 34.625rem;
    }
    .massege {
        width: 50rem;
        height: 3.125rem;
        background-color: black;
        border: 0.0625rem solid black;
        z-index: 9999;
        color: white;
        font-family: "楷体";
    }
    .massege p {
        height: 3.125rem;
        line-height: 3.125rem;
        float: left;
        background-color: black;
        border: 0rem solid black;
        text-align: center;
        padding: 0 0.3125rem 0 0.3125rem;
    }
    .massege .el-link {
        float: right;
        width: 3.125rem;
        height: 3.125rem;
    }
    .el-card__body {
        padding: 0.625rem;
    }
}
@media only screen and (max-width: 1080px) {
    .paging {
        width: 70%;
    }
    .video-js .vjs-big-play-button {
        font-size: 3em;
        line-height: 1.5em;
        height: 1.5em;
        width: 3em;
        display: block;
        position: absolute;
        top: 170px;
        left: 360px;
        padding: 0;
        cursor: pointer;
        opacity: 1;
        border: 0.06666em solid #fff;
        background-color: #2b333f;
        background-color: rgba(43, 51, 63, 0.7);
        border-radius: 0.3em;
        transition: all 0.4s;
    }
    .amap-info-sharp {
        bottom: -0.0625rem;
        left: 48.5%;
        position: absolute;
        color: #fff;
        z-index: -1;
        background-color: coral;
    }
    .info_mess {
        width: 100%;
        /* background-color: burlywood; */
        border-spacing: 0.0625rem;
        border: none;
        margin-bottom: 0.3125rem;
        font-family: "SourceHanSansSC-";
        margin-left: -0.3125rem;
        font-size: 0.875rem;
    }
    .info_mess tr td {
        word-wrap: break-word;
        word-break: break-all;
        /* text-align: start; */
        border: none;
        font-family: "SourceHanSansSC-" !important;
    }
    .mess_left {
        text-align: end;
        font-weight: bold;
        margin-top: 0.1875rem;
        /* background-color: aquamarine; */
    }
    .left_bottom {
        width: 2.5rem;
    }
    .right_bottom {
        width: 2.1875rem;
        /* background-color: darkgoldenrod; */
    }
    .mess_right {
        margin-top: 0.3125rem;
        padding: 0 0 0 0.625rem;
        text-align: start;
        /* background-color: chartreuse; */
    }
    #del-div {
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        transform: scale(1.2);
        color: #000000;
        /* background-color: cyan; */
    }
    .el-link {
        color: #ffffff !important;
    }
    .el-card {
        width: 26.5625rem !important;
    }
    #infoWindowTool {
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* background-color: darkgreen;
  width: 100%; */
    }
    .el-button {
    }
    .play {
        width: 50rem;
        height: 28.125rem;
        border: 0.0625rem solid black;
        background-color: cadetblue;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .loading {
        width: 50rem;
        height: 28.125rem;
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
    }
    .playmenu {
        position: absolute;
        z-index: 15;
        right: 0.9375rem;
        bottom: 0.3125rem;
    }
    element.style {
        top: 12.5rem;
        left: 12.5rem;

        z-index: 10;
    }
    .close {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        line-height: 2.5rem;
        z-index: 20;
        background-color: crimson;
    }
    .drag {
        top: 9.375rem;
        left: 16.25rem;
    }
    .massege {
        width: 50rem;
        height: 3.125rem;
        background-color: black;
        border: 0.0625rem solid black;
        z-index: 9999;
        color: white;
        font-family: "楷体";
    }
    .massege p {
        height: 3.125rem;
        line-height: 3.125rem;
        float: left;
        background-color: black;
        border: 0rem solid black;
        text-align: center;
        padding: 0 0.3125rem 0 0.3125rem;
    }
    .massege .el-link {
        float: right;
        width: 3.125rem;
        height: 3.125rem;
    }
    .el-card__body {
        padding: 0.625rem;
    }
    .footerbutton {
        width: 20% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
    }
}
</style>