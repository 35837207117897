import service from "../util/link"
import url from "../util/apiurl"

/**
 * 我的设备请求
 */
export function getmyeqm() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.myeqm,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function myeqmpage(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.myeqm,
            method: "get",
              params: {
                  page: obj
              }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function savevideo(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.savevideo,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}